import { operate } from '../util/lift';
import { noop } from '../util/noop';
import { createOperatorSubscriber } from './OperatorSubscriber';
import { innerFrom } from '../observable/innerFrom';
export function buffer(closingNotifier) {
  return operate((source, subscriber) => {
    let currentBuffer = [];
    source.subscribe(createOperatorSubscriber(subscriber, value => currentBuffer.push(value), () => {
      subscriber.next(currentBuffer);
      subscriber.complete();
    }));
    innerFrom(closingNotifier).subscribe(createOperatorSubscriber(subscriber, () => {
      const b = currentBuffer;
      currentBuffer = [];
      subscriber.next(b);
    }, noop));
    return () => {
      currentBuffer = null;
    };
  });
}
